import React from "react"
import PropTypes from "prop-types"

const API_KEY = "AIzaSyCC48eeFcflWa8klBhns7BGUNY6X50aPws"
const LOCATION = "49.245242,8.500264"

class GMaps extends React.Component {
  constructor(props) {
    super(props)
    this.jsEnabled = false
  }

  componentDidMount() {
    this.jsEnabled = true
    if (this.hasConsent()) {
      this.forceUpdate()
    }
  }

  hasConsent = () => document.cookie.indexOf("cookie_consent=1") !== -1

  render() {
    if (this.jsEnabled && this.hasConsent()) {
      return (
        <div className="gmaps-wrapper">
          <iframe
            className="gmaps"
            title="MAPS"
            width="1920"
            height="480"
            src={`https://www.google.com/maps/embed/v1/place?key=${API_KEY}&q=Praxis+Physiotherapie+KMP+Ilja+Hinz&center=${LOCATION}&zoom=17`}
          />
        </div>
      )
    }
    return (
      <div className="gmaps-wrapper-img">
        <p>Stimmen Sie der Verwendung von Cookies zu, um eine interaktive Karte zu aktivieren.</p>
        <img alt="Umgebung und Lage der Physiotherapie KMP - Ihr Körper im Mittelpunkt" src={this.props.placeholderImgSrc} />
      </div>)
  }
}

GMaps.propTypes = {
  placeholderImgSrc: PropTypes.string.isRequired,
}

export default GMaps
