import React from "react"

/* eslint-disable jsx-a11y/label-has-for */

import Wrapper from "../wrapper"
import Logo from "../logo"
import ServiceSelector from "../homepage/service-selector"
import GoogleMaps from "../homepage/google-maps"
import CookieConsent from "../cookie-consent"

import { isProduction } from "../../util"
import { STATIC_SERVER_PATH, WDS_PORT } from "../../config"

const fileWrap = (path = "") =>
  (isProduction
    ? `${STATIC_SERVER_PATH}${path}`
    : `http://localhost:${WDS_PORT}/public${path}`)

const createServiceObject = (label, img, text) => ({
  label,
  imgSrc: fileWrap(`/res/${img}.png`),
  imgAlt: img,
  text: text || undefined,
})

const gmapPlaceholder = fileWrap("/res/kmp_position_gmaps.jpg")

const services = [
  createServiceObject(
    "Manuelle Therapie",
    "manuelle-therapie",
    "Bei der Manuellen Therapie handelt es sich um ein Untersuchungs- und Behandlungskonzept zur konservativen Behandlung von Schmerzen und anderen Symptomen des Bewegungsapparates sowie gelenkbedingter Fehlfunktionen der Wirbelsäule und der Extremitäten. Das Ziel ist die Wiederherstellung der bestmöglichen und schmerzfreien Funktion des Haltungs- und Bewegungsapparates.",
  ),
  createServiceObject(
    "Kiefergelenkbehandlung",
    "cmd-kiefergelenkbehandlung",
    "Die Kiefergelenkbehandlung (craniomandibuläre Dysfunktion, kurz CMD) ist eine spezielle Behandlung von vielseitigen Beschwerden des Kiefers. Dazu zählen unter anderen Gelenkknacken, Schmerzen der Kaumuskulatur, Einschränkungen der Mundöffnung und -schließung, Zahnschmerzen, Knirschen sowie Schluckbeschwerden. Aber auch Ohrenschmerzen und Tinnitus können ihre Ursache hier haben. Eine enge Zusammenarbeit mit dem Zahnarzt ist deshalb besonders wichtig.",
  ),
  createServiceObject(
    "Krankengymnastik",
    "krankengymnastik",
    "Die Krankengymnastik ist einer der wichtigsten Bestandteile der Physiotherapie. Bei orthopädischen, chirurgischen Krankheitsbildern wird ein strukturierter Plan aus passiven und aktiven Bewegungsübungen durchgeführt. Ziel ist die Beseitigung von Schäden des Bewegungs- und Haltungsapparates und die Vorbeugung von weiteren Schäden.",
  ),
  createServiceObject(
    "PNF - Therapie",
    "kg-zns-pnf",
    "Die Propriozeptive Neuromuskuläre Fazilitation findet Anwendung bei Patienten mit Störungen des Bewegungs- und Stützapparates. Hierfür werden Rezeptoren, die in Gelenken, Sehnen und Muskeln liegen, stimuliert, um so auf neuromuskulären Weg die Motorik des Patienten zu verbessern. Die Stärken des Patienten werden genutzt, um die Schwachpunkte durch die richtige Reizzugabe und gezielte Fazilitation zu behandeln. Das Ziel ist die bestmögliche Funktionsfähigkeit und Selbstständigkeit des Patienten zu ermöglichen.",
  ),
  createServiceObject(
    "Bobath - Therapie",
    "kg-zns-bobath",
    "Bei Bobath handelt es sich um ein Konzept zur Rehabilitation bei Erkrankungen des zentralen Nervensystems als Folge von Unfällen oder Krankheiten. Auswirkungen der Betroffenen sind Bewegungsstörungen, Lähmungserscheinungen oder Spastik. Das Ziel ist es, den Lernprozess des Patienten anzuregen, um die Kontrolle über Muskelspannung und Bewegungsfunktion zu erarbeiten, um einen physiologischen Bewegungsablauf anzubahnen.",
  ),
  createServiceObject(
    "Manuelle Lymphdrainage",
    "manuelle-lymphdrainage",
    "Die Manuelle Lymphdrainage ist eine Entstauungstherapie, in der durch spezielle Massagegriffe die Lymphflüssigkeit abtransportiert wird. Dies erfolgt durch kreisförmige Verschiebetechniken, welche mit leichtem Druck angewendet werden. Die Flüssigkeit soll aus dem Gewebe in das Lymphgefäßsystem geleitet und über den Blutkreislauf abtransportiert werden.",
  ),
  createServiceObject(
    "Sektoraler Heilpraktiker",
    "heilpraktiker",
    "Der sektorale Heilpraktiker ist beschränkt auf das Gebiet der Physiotherapie. Durch diese Weiterbildung dürfen Patienten direkt bei uns in der Praxis behandelt werden, ohne sich vorher die Verordnung vom Arzt ausstellen lassen zu müssen. Eine Kostenübernahme durch Privatversicherungen oder Krankenkassen mit Heilpraktiker-Zusatzversicherung sind möglich.",
  ),
]

const services2 = [
  createServiceObject(
    "Stoßwellentherapie",
    "stosswelle",
  ),
  createServiceObject(
    "Wärmetherapie",
    "waermetherapie",
  ),
  createServiceObject(
    "Elektrotherapie",
    "elektrotherapie",
  ),
  createServiceObject(
    "Hausbesuche",
    "hausbesuche",
  ),
  createServiceObject(
    "Kryotherapie",
    "kryotherapie",
  ),
  createServiceObject(
    "Massage",
    "massage",
  ),
]

const news = "Wir suchen derzeit Verstärkung für unser Team: Physiotherapeut/-in (m/w/d) in Voll- oder Teilzeit" // "Aufgrund technischer Probleme sind wir derzeit unter folgender Telefonnummer erreichbar: $tel:0157 82035411$"

const newsObject = news.split("$").filter(x => !!x).map((x, i) => {
  if (x.startsWith("tel:")) {
    return {
      key: i.toString(),
      text: x.substring("tel:".length, x.length),
      type: "a",
      href: x.replace(" ", ""),
    }
  }
  return { key: i.toString(), text: x, type: "span" }
})

const copyright = () => {
  const std = "Physiotherapie KMP © 2018"
  const now = new Date()
  const rights = "Alle Rechte vorbehalten."
  if (now.getFullYear() !== 2018) return `${std} - ${now.getFullYear()}. ${rights}`
  return `${std}. ${rights}`
}

class HomePage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      mobileNavChecked: false,
    }
    this.markerRefs = {
      service: React.createRef(),
      team: React.createRef(),
      contact: React.createRef(),
    }
  }
  triggerMobileNav = () => {
    this.setState(prev => ({ mobileNavChecked: !prev.mobileNavChecked }))
  }
  softRefresh = () => {
    this.forceUpdate()
  }
  render() {
    const isOpen = this.state.mobileNavChecked
    const isClosed = !isOpen
    return (
      <React.Fragment>
        <CookieConsent onConsent={this.softRefresh} />
        <div className="homepage">
          <div className={`homepage__bg-wrapper ${news ? "homepage__with-news" : ""}`}>
            <div className="homepage__bg homepage__bg--green" />
          </div>
          <Wrapper>
            <header className="homepage__header">
              <nav className="homepage__header__nav">
                <a className="homepage__header__nav__logo" href="/">
                  <Logo height={44} innerStyle={{ fill: "#fff" }} />
                </a>
                <h1><a href="/">PHYSIOTHERAPIE KMP</a></h1>
                <ul className="desktop">
                  {[
                    { key: "service", link: "#service", label: "Service" },
                    { key: "team", link: "#team", label: "Team" },
                    { key: "contact", link: "#contact", label: "Kontakt" },
                  ].map(li => (
                    <li key={li.key}><a onClick={() => {}} href={li.link}>{li.label}</a></li>
                  ))}
                </ul>
                <span className="mobile mobile-logo-pos">&nbsp;</span>
                <a className="homepage__header__nav__login desktop" href="/signin">Einloggen</a>
                <div className="mobile mobile-nav">
                  <input onChange={this.triggerMobileNav} checked={isOpen} className="menu-btn-open" type="radio" name="mobile-nav" />
                  <div className="menu-open-btn-wrapper">
                    <span />
                    <span />
                    <span />
                  </div>
                  <input onChange={this.triggerMobileNav} checked={isClosed} className="menu-btn-closed" type="radio" name="mobile-nav" />
                  <div className="mobile-nav-inner">
                    <div className="menu-close-btn-wrapper">
                      <span />
                      <span />
                    </div>
                    <ul>
                      {[
                        { key: "mservice", link: "#service", label: "Service" },
                        { key: "mteam", link: "#team", label: "Team" },
                        { key: "mcontact", link: "#contact", label: "Kontakt" },
                      ].map(li => (
                        <li key={li.key}>
                          <label htmlFor={li.key}><a onClick={e => this.triggerMobileNav(e, li.key.substring(1, li.key.length))} href={li.link}><input onChange={() => {}} checked={isClosed} id={li.key} type="radio" name="mobile-nav" />{li.label}</a></label>
                        </li>
                      ))}
                    </ul>
                    <a className="nav-bottom" href="/signin">Einloggen</a>
                  </div>
                </div>
              </nav>
              { newsObject.length !== 0 &&
                <div className="homepage__news">
                  <span className="new">NEU</span>
                  {
                    newsObject.map(x => (
                      <React.Fragment key={x.key}>
                        {
                          x.type === "span" &&
                          <span className="text">{x.text}</span>
                        }
                        {
                          x.type === "a" &&
                          <a href={x.href}><span className="anchor">{x.text}</span></a>
                        }
                      </React.Fragment>
                    ))
                  }
                </div>
              }
              <div className="homepage__welcome">
                <section>
                  <h2>KMP - IHR KÖRPER IM MITTELPUNKT</h2>
                  <p>
                    Dieses Statement ist unser Leitbild. Zusammen mit Ihnen wollen wir gemeinsam einen Weg finden, Ihren Alltag wieder bestmöglich zu gestalten.
                  </p>
                  <div className="a-wrapper">
                    <a href="/join">JETZT ONLINE TERMIN VEREINBAREN</a>
                  </div>
                </section>
                <img className="desktop" alt="KMP Begrüßungsillustration" src={fileWrap("/res/kmp_welcome.png")} />
              </div>
              <div className="mobile welcome-mobile">
                <img alt="KMP Begrüßungsillustration" src={fileWrap("/res/kmp_welcome_mobile.png")} />
              </div>
            </header>
            <div ref={this.markerRefs.service} id="service" />
            <section className="homepage__service--a">
              <h2>Unser Leistungsspektrum</h2>
              <ServiceSelector
                parentClassName="service-selector"
                selections={services}
              />
            </section>
          </Wrapper>
          <div className="homepage__service--b__head__container">
            <div className="homepage__service--b__head" />
          </div>
          <section className="homepage__service--b">
            <Wrapper>
              <div className="homepage__service--b--inner">
                <h2>weitere Leistungen</h2>
                <div className="items">
                  {
                    services2.map(item => (
                      <div className="single-item" key={item.label}>
                        <img src={item.imgSrc} alt={item.label} />
                        <span>{item.label}</span>
                      </div>
                    ))
                  }
                </div>
              </div>
            </Wrapper>
          </section>
          <div ref={this.markerRefs.team} id="team" />
          <section className="homepage__team">
            <Wrapper>
              <h2>Das Team</h2>
              <div className="container container--head">
                {
                  [{
                    name: "Ilja Hinz",
                    imgSrc: fileWrap("/res/kmp-ilja-hinz-illustration.png"),
                    imgAlt: "KMP Team: Ilja Hinz",
                    text: "Physiotherapeut mit Weiterbildungen in: Manuelle Therapie, Manuelle Lymphdrainage, Krankengymnastik am Gerät, Propriozeptive Neuromuskuläre Fazilitation (PNF), Schlingentisch",
                  },
                  {
                    name: "Anne Hinz",
                    imgSrc: fileWrap("/res/kmp-anne-hinz-illustration.png"),
                    imgAlt: "KMP Team: Anne Hinz",
                    text: "Physiotherapeutin mit Weiterbildungen in: Manuelle Therapie, Manuelle Lymphdrainage, Krankengymnastik am Gerät, Bobath für Erwachsene, Kinesiotape, Craniomandibuläre Dysfunktion (CMD), sektoraler Heilpraktiker für Physiotherapie",
                  }].map(item => (
                    <div key={item.imgAlt} className="single-item">
                      <div className="container container--mobile">
                        <img alt={item.imgAlt} src={item.imgSrc} />
                        <h3>{item.name}</h3>
                      </div>
                      <p>{item.text}</p>
                    </div>
                  ))
                }
              </div>
            </Wrapper>
          </section>
          <div ref={this.markerRefs.contact} id="contact" />
          <section className="homepage__contact">
            <Wrapper>
              <h2>Kontakt</h2>
              <div className="items-wrap">
                <p>
                  Sie können uns erreichen <span className="hl">montags bis donnerstags von 07:30 bis 18:00 Uhr</span> und <span className="hl">freitags von 07:30 bis 16:00 Uhr</span>.
                </p>
                <div className="items">
                  <div className="item">
                    <img className="icon" alt="x" src={fileWrap("/res/kmp-contact-address.png")} />
                    <div>
                      <span>Physiotherapie KMP</span>
                      <span>Franz-Sigel-Straße 21</span>
                      <span>68753 Waghäusel</span>
                    </div>
                  </div>
                  <div className="item">
                    <img className="icon" alt="x" src={fileWrap("/res/kmp-contact-tel.png")} />
                    <div>
                      <div className="padding">
                        <a className="mb-10" href="tel:072547882652"><span>Tel: 07254 7882652</span></a>
                        <a href="tel:072549591471"><span>Fax: 07254 9591471</span></a>
                      </div>
                    </div>
                  </div>
                  <div className="item">
                    <img className="icon" alt="x" src={fileWrap("/res/kmp-contact-email.png")} />
                    <div className="padding">
                      <a href="mailto:info@physiotherapie-kmp.de"><span>info@physiotherapie-kmp.de</span></a>
                    </div>
                  </div>
                </div>
              </div>
            </Wrapper>
            <GoogleMaps placeholderImgSrc={gmapPlaceholder} />
          </section>
          <section className="homepage__footer">
            <Wrapper>
              <h2>Überzeugt?</h2>
              <div className="content">
                <p>Dann Termin vereinbaren und Ihrem Wohlbefinden ein Stückchen näher kommen!</p>
                <div className="a-wrapper">
                  <a href="/join">JETZT ONLINE TERMIN VEREINBAREN</a>
                </div>
              </div>
              <nav>
                <div className="nav">
                  <span>Navigation</span>
                  <ul>
                    <li><a href="#">Nach oben</a></li>
                    <li><a href="#service">Service</a></li>
                    <li><a href="#team">Team</a></li>
                    <li><a href="#contact">Kontakt</a></li>
                  </ul>
                </div>
                <div className="nav right">
                  <span>Informationen</span>
                  <ul>
                    <li><a href="/impressum">Impressum</a></li>
                    <li><a href="/agb">AGB</a></li>
                    <li><a href="/datenschutz">Datenschutz</a></li>
                  </ul>
                </div>
              </nav>
              <h4>{copyright()}</h4>
            </Wrapper>
          </section>
        </div>
      </React.Fragment>
    )
  }
}

export default HomePage
