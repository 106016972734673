import React from "react"

import PropTypes from "prop-types"

const tickRate = 30 * 1000

/* eslint-disable jsx-a11y/label-has-for */

class ServiceSelector extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      indexOfCurrent: 0,
    }
  }

  componentDidMount() {
    this.timer = setTimeout(() => this.nextItem(), tickRate)
  }

  nextItem = () => {
    clearTimeout(this.timer)
    const i = this.state.indexOfCurrent
    const l = this.props.selections.length
    this.setState(() => ({
      indexOfCurrent: (i + 1) % l,
    }))
    this.timer = setTimeout(() => this.nextItem(), tickRate)
  }

  selectItem = (newIndex) => {
    if (this.state.indexOfCurrent === newIndex) {
      return
    }
    clearTimeout(this.timer)
    this.setState(() => ({
      indexOfCurrent: newIndex,
    }))
    this.timer = setTimeout(() => this.nextItem(), tickRate)
  }

  render() {
    return (
      <div className={this.props.parentClassName}>
        {
          this.props.selections.map((item, i) => (
            <section key={item.imgAlt}>
              <input
                value={i === this.state.indexOfCurrent}
                checked={i === this.state.indexOfCurrent}
                className="disable"
                id={item.imgAlt}
                type="radio"
                name="services"
                onChange={() => this.selectItem(i)}
              />
              <header>
                <label htmlFor={item.imgAlt}>{item.label}</label>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 20">
                  <path d="M0,3.11V16.64a3.1,3.1,0,0,0,4.43,2.8l16.49-7.8a2,2,0,0,0,0-3.54L4.43.3A3.11,3.11,0,0,0,0,3.11Z" />
                </svg>
              </header>
              <article>
                <img alt={item.label} src={item.imgSrc} />
                <p>{item.text}</p>
              </article>
            </section>
          ))
        }
      </div>
    )
  }
}

ServiceSelector.propTypes = {
  parentClassName: PropTypes.string,
  selections: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
}

ServiceSelector.defaultProps = {
  parentClassName: "",
}

/*
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 20">
  <path d="M0,3.11V16.64a3.1,3.1,0,0,0,4.43,2.8l16.49-7.8a2,2,0,0,0,0-3.54L4.43.3A3.11,3.11,0,0,0,0,3.11Z" />
</svg>

*/

export default ServiceSelector
