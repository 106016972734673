export const PAGE_HOME = "/"
export const PAGE_LOGIN = "/signin"
export const PAGE_REGISTER = "/join"
export const PAGE_RECOVERY = "/recover"
export const PAGE_AGB = "/agb"
export const PAGE_PRIVACY = "/datenschutz"
export const PAGE_IMPRESSUM = "/impressum"
export const PAGE_RESET = (id, token) => `/reset/${id || ":id"}/${token || ":token"}`
export const PAGE_NOT_FOUND = "/404"
export const PAGE_CONFIRM = (id, hash) => `/verify/${id || ":id"}/${hash || ":hash"}`
export const PAGE_ACTIVATE = (id, token) => `/activate/${id || ":id"}/${token || ":token"}`
export const PAGE_APP_HOME = "/app/home"
export const PAGE_APP_PROFILE = "/app/profile"
export const PAGE_APP_BOOKING = "/app/booking"
export const PAGE_APP_BOOKING_NEW = "/app/booking/new"
export const PAGE_APP_BOOKING_SINGLE = (rhash, thash) => `/app/booking/${rhash || ":rhash"}/${thash || ":thash"}`

export const API_AUTH_SIGNIN = "/api/auth/signin"
export const API_AUTH_SIGNUP = "/api/auth/signup"
export const API_AUTH_SIGNOUT = "/api/auth/signout"
export const API_AUTH_CONFIRM = (id, hash) => `/api/auth/verify/${id || ":id"}/${hash || ":hash"}`
export const API_AUTH_RESET = "/api/auth/reset"
export const API_AUTH_CHANGE = "/api/auth/change"
export const API_AUTH_ACTIVATE = "/api/auth/activate"

export const API_BOOKING_NEW_RECIPE = "/api/booking/recipe"
export const API_BOOKING_NEW_APPOS = "/api/booking/appos"
export const API_BOOKING_APPOINTMENTS = (employee, week) => `/api/booking/appointments/${employee || ":employee"}/${week || ":week"}`
export const API_BOOKING_NEXT_APPOINTMENTS = "/api/booking/next"
export const API_BOOKING_ALLOWED = "/api/booking/allowed"
export const API_BOOKING_VERIFY_OWNER = (r, t) => `/api/booking/verify/${r || ":recipe"}/${t || ":therapy"}`

export const API_PROFILE_DATA = "/api/profile/data"
export const API_PROFILE_SAVE = "/api/profile/save"
export const API_PROFILE_PASSWORD = "/api/profile/password"
