import React from "react"
import PropTypes from "prop-types"

import { PAGE_PRIVACY } from "../routes"

class CookieConsent extends React.Component {
  constructor() {
    super()
    this.state = {
      hidden: true,
    }
  }

  componentDidMount() {
    if (document.cookie.indexOf("cookie_consent=2") !== -1) return
    if (document.cookie.indexOf("cookie_consent=1") === -1) {
      setTimeout(() => this.setState(() => ({ hidden: false })), 250)
    }
  }

  decline = () => {
    console.log("declined cookies")
    const now = new Date()
    now.setDate(now.getDate() + 1)
    document.cookie = `cookie_consent=2; expires=${now}; path=/`
    this.setState(() => ({ hidden: true }))
    this.props.onConsent()
  }

  consent = () => {
    console.log("accepted cookies")
    const now = new Date()
    now.setMonth(now.getMonth() + 1)
    document.cookie = `cookie_consent=1; expires=${now}; path=/`
    this.setState(() => ({ hidden: true }))
    this.props.onConsent()
  }

  render() {
    if (this.state.hidden) return (null)
    return (
      <div className="cookie-consent">
        <p>
          Diese Website verwendet Cookies
          – nähere Informationen dazu und zu Ihren Rechten als
          Benutzer finden Sie in
          unserer <a href={PAGE_PRIVACY}>Datenschutzerklärung</a> am Ende der Seite.
          Klicken Sie auf „Ich stimme zu“,
          um optionale Cookies zu akzeptieren und unsere Website im vollem Umfang nutzen zu können.
        </p>
        <button
          className="consent"
          onClick={() => this.consent()}
        >
          Ich stimme zu
        </button>
        <button
          className="decline"
          onClick={() => this.decline()}
        >
          Ich lehne ab
        </button>
      </div>
    )
  }
}

CookieConsent.propTypes = {
  onConsent: PropTypes.func,
}

CookieConsent.defaultProps = {
  onConsent: () => {},
}

export default CookieConsent
