import "babel-polyfill"

import React from "react"
import ReactDom from "react-dom"

import LandingPage from "../shared/landing"
import { APP_CONTAINER_SELECTOR } from "../shared/config"

import "./devCss"

const rootElement = document.querySelector(APP_CONTAINER_SELECTOR) // eslint-disable-line

const wrapApp = AppComponent =>
  (
    <AppComponent />
  )

ReactDom.hydrate(wrapApp(LandingPage), rootElement)
