import React from "react"
import Helmet from "react-helmet"
import HomePage from "./component/page/home-page"

const APP_NAME = "Praxis Physiotherapie KMP - Ihr Körper im Mittelpunkt"

const Landing = () => (
  <React.Fragment>
    <Helmet
      titleTemplate={`${APP_NAME}`}
      defaultTitle={APP_NAME}
      meta={[
        { name: "description", content: "Praxis Physiotherapie KMP, Inh. Ilja Hinz. Ihr Körper im Mittelpunkt - dieses Statement ist unser Leitbild. Franz-Sigel-Straße 21, 68753 Waghäusel. Tel 07254 7882652, E-Mail info@physiotherapie-kmp.de" },
        { property: "og:title", content: APP_NAME },
      ]}
    />
    <HomePage />
  </React.Fragment>
)

export default Landing
